<template>
  <v-container>
    <v-overlay :value="requestLoading" opacity="1">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card elevation="0" v-if="!requestLoading">
      <v-card-subtitle v-if="!this.requestError && !this.requestIdError">
        <p class="subtitle-1 font-weight-bold">Reset Password</p>
        Your new password must be different from previously used passwords
      </v-card-subtitle>
      <v-card-text class="mt-0" v-if="!this.requestError && !this.requestIdError">
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                ref="new_password"
                v-model="new_password"
                :rules="[rules.required, rules.minimum(6)]"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                label="New Password"
                outlined
                dense
                @click:append="show2 = !show2"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="confirm_password"
                :rules="[rules.required, passwordConfirmationRule]"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                label="Confirm Password"
                outlined
                dense
                @click:append="show3 = !show3"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn block color="primary" type="submit" :loading="loading">
                Set New Password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-subtitle v-else>
        <p class="subtitle-1 font-weight-bold">Reset Password</p>
        {{ requestIdError }}
      </v-card-subtitle>
    </v-card>

    <v-dialog
      v-model="success"
      max-width="400"
      persistent
    >
      <v-card>
        <v-btn icon @click="success = false" absolute right style="top: 5px; right: 5px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h6">
          <span class="green--text text--darken-1">
            <v-icon class="green--text me-1"> mdi-check-circle-outline </v-icon>
            Successfully Reset Password
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-btn block color="primary" @click="loginAccount">Login my account</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  props: {
    id: {
      required: true,
    },
  },
  data: () => ({
    rules: formRules,
    show2: false,
    show3: false,
    new_password: "",
    confirm_password: "",
    success: false,
  }),
  created() {
    this.checkId();
  },
  computed: {
    ...mapState("reset_password", [
      "requestError",
      "requestIdError",
      "requestLoading",
      "error",
      "resetError",
      "loading",
    ]),
    passwordConfirmationRule() {
      return () => this.new_password === this.confirm_password || "Password must match";
    },
  },
  methods: {
    ...mapActions("reset_password", ["doCheckRequest", "doReset"]),
    forgotValError(val) {
      if (val == this.forgotValErrorText) {
        return this.forgotValErrorDesc;
      } else {
        return true;
      }
    },
    async checkId() {
      if (!this.requestLoading) {
        const formData = new FormData();
        formData.append("id", this.id);

        await this.doCheckRequest(formData);
      }
    },
    async handleSubmit() {
      if (this.$refs.form.validate() && !this.loading) {
        const formData = new FormData();
        formData.append("id", this.id);
        formData.append("new_password", this.new_password);
        formData.append("confirm_password", this.confirm_password);

        await this.doReset(formData);

        if (!this.error && !this.resetError) {
          this.success = true;
        } else {
          window.location.reload();
        }
      }
    },
    loginAccount() {
      // this.$router.push({
      //   name: "Home",
      //   query: {
      //     overlay: 'login'
      //   }
      // })
      window.location.href = "/?overlay=login";
    },
  },
};
</script>
